@import '../app.scss';

.box-details-container {
  position: relative;
  width: 100%;
  height: 95%;
  background-image: url(../../images/background-monitoring.png);
  @include cover-background;
  padding: $SPACING;
  .box-details {
    &-title,
    &-using,
    &-calling {
      border: 3px solid $WHITE;
      border-radius: 40px;
      font-weight: 600;
      font-size: 30px;
      padding: 0 25px;
      color: $WHITE;
    }
    &-title {
      background: $PRIMARY_COLOR_BLUR;
    }
    &-using,
    &-calling {
      background: $PINK_BLUR;
      max-width: 785px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-calling {
      background: $GREEN;
    }
  }
  .btn-history {
    background: $WHITE;
    border: 3px solid $PRIMARY_COLOR;
    color: $PRIMARY_COLOR;
    border-radius: $RADIUS_NORMAL;
    font-weight: 400;
    font-size: 30px;
    padding: 20px 0;
    width: 15%;
    &:hover {
      background-color: $WHITE;
    }
  }
  .box-service-wrapper {
    position: relative;
    border-radius: $RADIUS_LARGE;
    .btn-switch {
      position: absolute;
      bottom: 3%;
      right: 4%;
      z-index: 1;
    }
    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
    .box-service,
    .box-service-no-camera {
      cursor: pointer;
      position: absolute;
      top: 2%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      width: 96%;
      height: 96%;
      padding: 5px 10px;
      border-radius: $RADIUS_LARGE;
      @include cover-background;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      &-switch {
        .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
          background-color: $PRIMARY_COLOR;
          opacity: 1;
        }
        .MuiSwitch-thumb {
          background-color: $WHITE;
        }
      }
    }
    .box-service {
      background-image: url(../../images/background-box-service.png);
    }
    .box-service-no-camera {
      background-color: $LIGHTER_GRAY;
      .icon-no-camera {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        svg {
          font-size: 90px;
        }
      }
    }
    .participant-webcam, .participant-webcam-zoom{
      height: 100%;
      width: 100%;
      video{
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: $RADIUS_LARGE !important;
      }
    }
  }
  .control-panel {
    &-title {
      color: $WHITE;
      background-color: $PRIMARY_COLOR;
      font-weight: 800;
      font-size: 24px;
      text-align: center;
    }
    &-switch {
      .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        background-color: $PRIMARY_COLOR;
        opacity: 1;
      }
      .MuiSwitch-thumb {
        background-color: $WHITE;
      }
    }
  }
  .button-call {
    background: $WHITE;
    padding: 1rem;
    svg {
      font-size: 40px;
    }
    &:hover {
      background: $WHITE;
    }
    &.Mui-disabled {
      background-color: $WHITE;
      opacity: 0.5;
    }
  }
  .button-call {
    svg {
      color: $GREEN;
    }
  }
  .button-message {
    color: $WHITE;
    font-size: 70px;
    transform: scaleX(-1);
    cursor: pointer;
  }
  .button-back {
    background-color: $WHITE;
    border: 1px solid $PRIMARY_COLOR;
    color: $DARK_BLUE;
    font-weight: 700;
    font-size: 25px;
    &:hover {
      background: $WHITE;
    }
  }
  .talk-bubble {
    margin: 40px;
    display: inline-block;
    width: 50%;
    height: 40%;
    background-color: $WHITE;
    position: absolute;
    bottom: 8%;
    right: 10%;
    border-radius: $RADIUS_LARGE;
    &::after {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      left: -30px;
      right: auto;
      bottom: 0;
      border: 30px solid;
      z-index: 1;
      border-color: transparent transparent $WHITE transparent;
    }
  }
  .video-call {
    height: 350px;
    width: 540px;
    @include cover-background;
    border-radius: $RADIUS_LARGE;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .button-end-call,
    .button-camera,
    .button-volume {
      background: $WHITE;
      padding: 1rem;
      svg {
        font-size: 40px;
      }
      &:hover {
        background: $WHITE;
      }
    }
    .button-end-call {
      svg {
        color: $ERROR_COLOR;
      }
    }
    .button-volume {
      svg {
        color: $BLACK;
      }
    }
    .button-camera {
      svg {
        color: $PRIMARY_COLOR;
      }
    }
    .my-webcam{
      height: 100%;
      width: 100%;
      video{
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: $RADIUS_LARGE;
      }
    }
  }
  .calling-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: $OVERLAY_BLACK;
    z-index: 1;
    p {
      color: $WHITE;
      font-weight: 600;
      font-size: 60px;
    }
    .button-end-call,
    .button-camera,
    .button-volume {
      background: $WHITE;
      padding: 1rem;
      svg {
        font-size: 40px;
      }
      &:hover {
        background: $WHITE;
      }
    }
    .button-end-call {
      svg {
        color: $ERROR_COLOR;
      }
    }
    .button-volume {
      svg {
        color: $BLACK;
      }
    }
    .button-camera {
      svg {
        color: $PRIMARY_COLOR;
      }
    }
  }
}
