@import '../app.scss';

.monitoring-container {
  width: 100%;
  height: 95%;
  background-image: url(../../images/background-monitoring.png);
  @include cover-background;
  padding: $SPACING;
  .filter-wrapper {
    width: 90%;
    border-radius: $RADIUS_LARGE;
    padding: 1rem 2rem;
    .btn-search {
      border-radius: $XRADIUS_LARGE;
    }
  }
  .text-result {
    color: $WHITE;
  }
  .box-service-wrapper {
    position: relative;
    border-radius: $RADIUS_LARGE;
    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
    .box-service {
      cursor: pointer;
      position: absolute;
      top: 2%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      width: 96%;
      height: 96%;
      padding: 5px 10px;
      border-radius: $RADIUS_LARGE;
      @include cover-background;
      background-image: url(../../images/background-box-service.png);
      .box-name,
      .time-use {
        border-radius: $RADIUS_LARGE;
        color: $WHITE;
        width: fit-content;
        padding: 0 12px;
      }
      .box-name {
        background-color: $PRIMARY_COLOR_BLUR;
        z-index: 1;
        position: absolute;
      }
      .time-use {
        background-color: $PINK_BLUR;
        margin-top: 30px;
      }
    }
    .under-repair {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: $PINK_BLUR;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 0;
      border-radius: $RADIUS_LARGE;
      @include flex-center;
      color: $WHITE;
      text-align: center;
      font-size: 40px;
      text-shadow: 2px 0 0 $ORANGE, -2px 0 0 $ORANGE, 0 2px 0 $ORANGE,
        0 -2px 0 $ORANGE, 1px 1px $ORANGE, -1px -1px 0 $ORANGE,
        1px -1px 0 $ORANGE, -1px 1px 0 $ORANGE;
    }
  }
  .boxes-sort {
    svg {
      font-size: 50px;
      color: $WHITE;
      &:last-child {
        cursor: pointer;
      }
    }
  }
  .boxes-pagination {
    width: 85%;
    p,
    button,
    .MuiPaginationItem-root {
      color: $WHITE;
    }
  }
}
