@import '../app.scss';

.header-container {
  .logo-wrapper {
    width: 250px;
    margin-left: $SPACING * -1;

    .logo-image {
      width: 100%;
    }

    .logo-item {
      display: flex;
      align-items: flex-end;
      align-self: center;

      .MuiSvgIcon-root {
        width: auto;
        height: auto;
        padding-left: 2px;
      }
    }
  }

  .header-action {
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
  }

  .user-wrapper {
    color: $DARK_BLUE;
  }
}
