/******  COLORS  ******/
$PRIMARY_COLOR: #3399bd;
$PRIMARY_LIGHT_COLOR: #3bafd9;
$SECOND_COLOR: #9c27b0;
$SUCCESS_COLOR: #2e7d32;
$ERROR_COLOR: #d32f2f;
$BODY_COLOR: #f8f8fb;
$GREEN: #52ad67;
$BLUE: #53a8ff;
$LIGHT_BLUE: #e6f7f8;
$WHITE: #ffffff;
$LIGHT_WHITE: rgba(255, 255, 255, 0.8);
$YAM: #4dc7cf;
$YARM_LIGHT: #c3edf0;
$GRAY: #707070;
$LIGHT_GRAY: #f5f6fa;
$GRAY_BOX_SHADOW: rgba(0, 0, 0, 0.078);
$BLUE_SHADOW: rgba(51, 153, 189, 0.5);
$BLACK: #141a1a;
$RED: #ce6652;
$SKIN: #fcc9b9;
$DARK_BLUE: #8a9ead;
$WHITE: #ffffff;
$PRIMARY_COLOR_BLUR: rgba(51, 153, 189, 0.8);
$PINK_BLUR: rgba(236, 129, 212, 0.8);
$ORANGE: #ff624d;
$OVERLAY_BLACK: #000000b8;
$LIGHTER_GRAY: #c4c4c4;

/******* FONTS ******/
$FONT_SIZE_XLARGE: 22px;
$FONT_SIZE_LARGE: 18px;
$FONT_SIZE_NORMAL: 14px;
$FONT_SIZE_SMALL: 12px;

/****** BORDERS  **********/
$XRADIUS_LARGE: 30px;
$RADIUS_LARGE: 20px;
$RADIUS_NORMAL: 10px;
$RADIUS_SMALL: 5px;

/** UNIT **/
$HEADER_HEIGHT: 70px;
$SPACING: 24px;
