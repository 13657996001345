@import '../../app.scss';

.group-select-container {
  .form-control {
    margin: 12px;
    width: 300px;
  }

  .select-all-text {
    font-weight: 500;
  }

  .selected-all {
    background-color: 'rgba(0, 0, 0, 0.08)';
    &:hover {
      background-color: 'rgba(0, 0, 0, 0.08)';
    }
  }
}
