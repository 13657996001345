@import '../app.scss';

.monitoring-history-container {
  width: 100%;
  height: 95%;
  background-image: url(../../images/background-monitoring.png);
  @include cover-background;
  padding: $SPACING;
  .box-details {
    &-title {
      border: 3px solid $WHITE;
      border-radius: 40px;
      font-weight: 600;
      font-size: 50px;
      padding: 0 25px;
      color: $WHITE;
      margin-bottom: 10px;
      background: $PRIMARY_COLOR_BLUR;
    }
  }
  .boxes-pagination {
    width: 85%;
    p,
    button,
    .MuiPaginationItem-root {
      color: $WHITE;
    }
  }
}
