@import '../app.scss';

.history-details-container {
  width: 100%;
  height: 95%;
  background-image: url(../../images/background-monitoring.png);
  @include cover-background;
  padding: $SPACING;
  .box-details {
    &-title,
    &-using {
      border-radius: 40px;
      font-weight: 600;
      font-size: 40px;
      padding: 0 25px;
    }
    &-title {
      border: 3px solid $PRIMARY_COLOR;
      background: $PRIMARY_COLOR_BLUR;
      color: $WHITE;
    }
    &-using {
      background-color: $WHITE;
      color: $PRIMARY_COLOR;
    }
  }
  .container-video {
    overflow-x: scroll;
    overflow-y: hidden;
    padding-top: 5px;
    padding-left: 5px;
  }
  .box-service-wrapper {
    border-radius: $RADIUS_LARGE;
    outline: 3px solid $WHITE;
    padding: 3px;
    &-zoom {
      height: 100%;
    }
    .box-service {
      height: 100%;
      video {
        object-fit: fill;
        border-radius: $RADIUS_LARGE;
      }
    }
  }
}
